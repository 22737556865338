import React from 'react'

export interface WithConditionProps {
	hide?: boolean
}

export const withCondition = <P extends object>(
	WrappedComponent: React.ComponentType<P>,
) => {
	const WithCondition: React.FC<P & WithConditionProps> = (props) => {
		const { hide, ...restProps } = props

		if (hide) return null
		return <WrappedComponent {...(restProps as P)} />
	}

	WithCondition.displayName = `WithCondition(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

	return WithCondition
}
