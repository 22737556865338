import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/legacy/colors'

import { Radii } from '../borders'
import { SHADOWS } from '../constants'
import { SPACING } from '../space'
import { FONT_SIZES, WEIGHT } from '../typography'

const helpers = createMultiStyleConfigHelpers(inputAnatomy.keys)

export enum INPUT_ENUM {
	base = 'base',
	primary = 'primary',
	secondary = 'secondary',
}

export const inputTheme = helpers.defineMultiStyleConfig({
	baseStyle: () => ({
		field: {
			bg: ColorTokens.white,
		},
	}),
	sizes: {
		md: {
			field: {
				height: '36px',
				px: '12px',
				fontSize: '14px',
			},
		},
	},
	variants: {
		[INPUT_ENUM.base]: () => ({
			field: {
				height: 10,
				bg: COLORS.background[12],
				color: COLORS.gray[12],
				fontWeight: WEIGHT.medium,
				fontSize: FONT_SIZES.sm,
				lineHeight: SPACING[5],
				borderRadius: Radii.lg,
				px: '12px',
				py: '10px',

				border: '1px solid',
				borderColor: COLORS.gray[4],

				_hover: {
					borderColor: COLORS.background[9],
				},
				_focus: { boxShadow: SHADOWS.focus, zIndex: 5 },

				_disabled: {
					bg: COLORS.background[11],
					borderColor: COLORS.gray[4],
					color: COLORS.gray[12],
				},
			},
		}),
		[INPUT_ENUM.primary]: () => ({
			addon: {
				color: ColorTokens.text_primary,
				background: ColorTokens.white,
				fontWeight: 400,
				fontSize: '14px',
				borderRadius: '8px',
				border: '1px solid',
				borderColor: COLORS.background[9],
				_focus: {
					boxShadow: SHADOWS.focus,
				},
			},
			field: {
				color: ColorTokens.text_primary,
				background: ColorTokens.white,
				borderRadius: '8px',
				border: '1px solid',
				borderColor: COLORS.background[9],
				fontWeight: 400,
				_placeholder: {
					color: COLORS.background[6],
				},
				_focus: {
					boxShadow: SHADOWS.focus,
				},
			},
		}),
		[INPUT_ENUM.secondary]: () => ({
			field: {
				height: 9,
				color: 'text',
				background: COLORS.gray[2],
				px: 3,
				borderRadius: 'md',
				borderWidth: '1px',
				borderColor: ColorTokens.black,
				fontWeight: 400,
				fontSize: 'sm',
				_focus: {
					boxShadow: 'focus',
				},
			},
		}),
	},
	defaultProps: {
		variant: INPUT_ENUM.base,
	},
})
