import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

import { getToken } from '@/utils/helpers/token'

import { NUCLEUS_URL } from '../config'

const staggeredBaseQuery = retry(
	fetchBaseQuery({
		baseUrl: NUCLEUS_URL,
		prepareHeaders: (headers) => {
			const token = getToken() || null

			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			headers.set('role-permissioning', 'default')

			return headers
		},
	}),
	{ maxRetries: 1 },
)

export const baseApi = createApi({
	reducerPath: 'base',
	baseQuery: staggeredBaseQuery,
	refetchOnFocus: true,
	refetchOnReconnect: true,
	tagTypes: [
		'GET_ALL_FORMS',
		'GET_LEAD_VIEWS',
		'GET_FORM_BY_ID',
		'GET_ALL_FORM_VERSIONS_BY_ID',
		'GET_PUBLISHED_FORM_BY_ID',
		'GET_FORM_QUESTIONS',
		'GET_ALL_EVENTS',
		'GET_ALL_QUEUES',
		'GET_QUEUE_BY_ID',
		'GET_MEMBER_SCHEDULER_CONFIG',
		'GET_MEMBER_SCHEDULER_CONFIG_BY_ID',
		'GET_ALL_STAGES',
		'GET_MEMBER',
		'GET_MEMBERS',
		'GET_LEAD_NOTES',
		'GET_ALL_LEADS',
		'GET_MEETING_BOOKED_FORM',
		'GET_VACATIONS',
		'GET_VACATIONS_BY_MEMBER_ID',
		'GET_TAGS',
		'GET_NOTIFICATIONS_SETTINGS',
		'GET_ALL_MEETINGS',
		'GET_LEAD_MEETINGS',
		'GET_CHECKLIST_ITEMS',
		'GET_ALL_FONTS',
		'GET_ZAPIER_API_KEY',
		'GET_ALL_IMPORTED_QUESTIONS_BY_FORM_ID',
		'GET_REMINDERS',
		'GET_FIELD_MAPPINGS',
		'GET_NOTIFICATION_TEMPLATE',
		'GET_EVENT_REMINDERS',
		'GET_EVENT_BY_ID',
	],
	endpoints: () => ({}),
})
