import { PartialExcept, SchedulingLinkField } from 'shared-utils'

import { TimeSlot } from '@/app/types'
import { QueueWithMembers } from '@/modules/queues/types'

export interface EventReminderTable {
	id: number
	event_id: number
	created_at: string
	subject: string
	body: string
	logo: string
	times: { unit: string; value: number }[] | null
	link_details: boolean
}

export enum EventTypeEnum {
	'personal' = 'personal',
	'global' = 'global',
	'group' = 'group',
}

export enum EventConferecingEnum {
	'native' = 'native',
	'zoom' = 'zoom',
	'in_person' = 'in_person',
}

export type EventFutureAvailabilityDays = {
	number_of_days?: number
	is_active: boolean
}

export type EventFutureAvailabilityDateRange = {
	is_active: boolean
	start_date?: string
	end_date?: string
}

export type EventFutureAvailability = {
	days?: EventFutureAvailabilityDays
	date_range?: EventFutureAvailabilityDateRange
}

export interface EventInvitee {
	email: string
	is_optional: boolean
}

export interface EventInviteesConfig {
	is_enabled: boolean
	invitees?: EventInvitee[]
}

export interface WebinarSettingsType {
	type: 'max_number' | 'ratio'
	assignees?: number
	attendees: number
}

export interface SchedulerEventTable {
	id: number
	team_id: number
	name: string
	title: string
	location: string | null
	duration: number
	description: string
	enabled: boolean
	pre_meeting_buffer: number
	post_meeting_buffer: number
	notice_period: number
	active_time: Array<TimeSlot> | null
	is_availability: boolean
	type: EventTypeEnum
	scheduling_fields: SchedulingLinkField[]
	timeslot_increment: number | null
	calendar_title: string | null
	calendar_description: string | null
	created_by?: string | null
	host_id?: string | null
	conferencing: EventConferecingEnum | null
	event_name?: string | null
	conferencing_native_description?: boolean | null
	notification_template_id: string | null
	future_availability: EventFutureAvailability | null
	invitees_config: EventInviteesConfig | null
	disable_invite: boolean | null
	is_webinar_enabled?: boolean | null
	webinar_settings?: WebinarSettingsType | null
}

export type EventAssignee = {
	id: string
	first_name?: string | null
	last_name?: string | null
	email: string
	avatar_url?: string | null
}

export interface EventFavorite {
	id: string
	member_id: string
	event_id: number
}

export interface SchedulerEvent extends SchedulerEventTable {
	reminders: EventReminderTable | null
	assignees: { member: EventAssignee }[]
	favorites: EventFavorite[]
}

export interface SchedulerEventWithQueues extends SchedulerEvent {
	reminders: EventReminderTable | null
	queues: {
		is_optional: boolean
		queue_id: number
		event_id: number
		queue: QueueWithMembers
	}[]
}

export interface CreateEventPayload {
	event: Partial<SchedulerEventTable>
}

export interface UpdateEventPayload {
	event: PartialExcept<SchedulerEventTable, 'id'>
}

export interface UpdateEventReminderPayload {
	reminders: PartialExcept<EventReminderTable, 'event_id'>
}

export interface LinkedForm {
	id: number
	name: string
}

export interface EventQueuesTable {
	id: string
	event_id: number
	queue_id: number
	is_optional: boolean
}

export type AttachQueuePayload = {
	eventId: number | string
	queueId: number | string
	is_optional?: boolean
	queueName?: string // don't send this field in the payload, only for the UI
}

export type DetachQueuePayload = {
	eventId: number | string
	queueId: number | string
}
