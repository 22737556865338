import layup from 'layup-js'

import { VITE_LAYUP_API_KEY } from '@/app/config'
import { MemberType } from '@/app/user/api/members/type'

export const identifyGroupWithLayup = (userData: MemberType) => {
	layup.init(VITE_LAYUP_API_KEY, {
		group_id: userData?.team_id?.toString() || undefined,
	})
}
