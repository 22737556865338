import { Center, CenterProps, Spinner } from '@chakra-ui/react'

import { withCondition } from '@/common/hoc/withCondition'

type Props = CenterProps

const LoadingContainer = withCondition((props: Props) => {
	return (
		<Center h="full" w="full" {...props}>
			<Spinner />
		</Center>
	)
})

export default LoadingContainer
