export enum FeatureFlags {
	salesloft_oAuth = 'salesloft_oAuth',
	'workflows-3-0' = 'workflows-3-0',
	'workflows-legacy' = 'workflows-legacy',
	paid_workflow_triggers = 'paid-workflow-triggers',
	'release-queue-analytics' = 'release-queue-analytics',
	'release-queue-logs' = 'release-queue-logs',
	'display-scheduler-wait-time' = 'display-scheduler-wait-time',
	'amplemarket-integration' = 'amplemarket-integration',
	'amplemarket-start-sequence' = 'amplemarket-start-sequence',
	'disconnected-integrations-allowed' = 'disconnected-integrations-allowed',
	'slate-rich-text-editor' = 'slate-rich-text-editor',
	'workflow-version-viewer' = 'workflow-version-viewer',
	'forms-3' = 'forms-3',
	'workflow-test-runs' = 'workflow-test-runs',
	'workflow-advanced-mode' = 'workflow-advanced-mode',
	'pipeline-bulk-actions' = 'pipeline-bulk-actions',
	'copy-workflow-to' = 'copy-workflow-to',
	'additional-attendees' = 'additional-attendees',
	'meetings_v2' = 'meetings_v2',
	'reminders_v2' = 'reminders_v2',
	'stacking-and-elements' = 'stacking-and-elements',
	'custom-salesforce-objects' = 'custom-salesforce-objects',
	'wf-plus-button-true-false-connection' = 'wf-plus-button-true-false-connection',
	'deprecate-parent-ids-children' = 'deprecate-parent-ids-children',
	'leads-view-enabled' = 'leads-view-enabled',
	'marketo-enabled' = 'marketo-enabled',
	'update-workflow-by-node-diff' = 'update-workflow-by-node-diff',
	'logic-jumps' = 'logic-jumps',
	'wf_notifications' = 'wf_notifications',
	'slack-resources-json' = 'slack-resources-json',
	'event_webinar_config' = 'event_webinar_config',
	'allow-crm-specific-node-references' = 'allow-crm-specific-node-references',
}
