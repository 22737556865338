import { PartialExcept } from 'shared-utils'

import { MemberType } from '@/app/user/api/members/type'

export enum QueueDistributionType {
	fairness = 'fairness',
	availability = 'availability',
	group = 'group',
}

export enum QueueTypeEnum {
	roundrobin = 'roundrobin',
	group = 'group',
}

export interface QueueTable {
	id: number
	team_id: number
	name: string
	type: QueueTypeEnum
	reschedule_type: string | null
	maximize_by: QueueDistributionType
	created_at: string
	updated_at: string
	active: boolean
	queue_name?: string | null
}

export interface QueueMemberTable {
	queue_id: number
	member_id: string
	id: number
	priority: number
	enabled: boolean
	meetings_count: number
	calibration_count: number
	is_optional: boolean
}

export interface QueueMember extends QueueMemberTable {
	member: Partial<MemberType>
}

export interface QueueWithMembers extends QueueTable {
	queues_members: Array<QueueMember>
}

export interface CreateQueuePayload {
	noOptimistic?: boolean
	queue: Partial<QueueTable> & {
		queueMembers?: string[] | null
	}
}

export interface UpdateQueuePayload {
	queue: PartialExcept<QueueTable, 'id'>
}

export interface CreateQueueMemberPayload {
	queueMember: PartialExcept<QueueMemberTable, 'queue_id'>
	event_id?: number | null
}
export interface UpdateQueueMemberPayload {
	queueMember: Partial<QueueMemberTable> & {
		id: number
		queue_id: number
		member_id: string
	}
}

export interface LinkedForm {
	id: number
	name: string
}
